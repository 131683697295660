<template>
  <div
    fluid
  >
    <b-row
      align-v="center"
      class="bg-white py-5 py-md-0"
    >
      <b-col
        lg="6"
        sm="12"
      >
        <div class="details">
          <div class="logo">
            <b-img
              :src="require('@/assets/images/logo/muslim-do-logo.png')"
              class="logo-img"
              alt="logo"
            />
            <div class="text-center text-black">
              <p>Login with your data you entered <br> during your registration</p>
            </div>
          </div>
          <b-row align-h="center">
            <b-col
              lg="6"
              sm="8"
            >
              <b-form @submit.prevent="login()">
                <b-form-group>
                  <b-form-select
                    v-model="selectedUserType"
                    :options="loginOptions"
                    required
                    @change="setIsSuperAdmin"
                  >
                    <template #first>
                      <b-form-select-option
                        :value="null"
                        disabled
                      >
                        -- Please select account type --
                      </b-form-select-option>
                    </template>
                  </b-form-select>
                </b-form-group>
                <div class="form-group has-icon">
                  <label
                    for="email"
                    class="email-label"
                  >Email / Username</label>
                  <feather-icon
                    icon="MailIcon"
                    size="12"
                    class="form-control-feedback"
                  />
                  <validation-provider
                    #default="{ errors }"
                    name="Email/Username"
                    rules="required"
                  >
                    <b-form-input
                      id="login-email"
                      v-model="userEmail"
                      :state="errors.length > 0 ? false:null"
                      name="login-email"
                      placeholder="Email or Username"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>

                </div>
                <div class="form-group has-icon">
                  <label
                    for="password"
                    class="pass-label"
                  >Password</label>
                  <feather-icon
                    icon="LockIcon"
                    size="12"
                    class="form-control-feedback"
                  />
                  <b-form-input
                    id="password"
                    v-model="password"
                    :type="passwordFieldType"
                    class="form-control"
                    placeholder="Password"
                  />
                  <feather-icon
                    :icon="passwordToggleIcon"
                    size="12"
                    class="form-control-eye"
                    @click="togglePasswordVisibility()"
                  />
                </div>
                <div
                  class="text-right"
                >
                  <b-link
                    :to="{name:'reset-password-request'}"
                  >
                    Forget Password
                  </b-link>
                </div>
                <b-button
                  type="submit"
                  class="btn btn-primary mt-2 button"
                >
                  Login
                </b-button>
              </b-form>
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col
        md="6"
        class="carousel pt-5 d-none d-lg-block"
      >
        <b-row align-h="center">
          <div class="login-details">
            <div class="top">
              <p class="text-center display-4 text-light">
                LOGIN
              </p>
              <p class="text-center sign-up">
                haven't account yet?
                <router-link :to="{name:'register'}">
                  Sign up
                </router-link>
              </p>
            </div>
            <carousel />
          </div>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import Carousel from '@/common/components/Login/Carousel.vue'

export default {
  components: {
    Carousel,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      userEmail: '',
      password: '',
      loginOptions: [
        { value: 'superAdminLoginData', text: 'Super Admin' },
        { value: 'organizationAdminLoginData', text: 'Organization Admin' },
      ],
      selectedUserType: null,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  methods: {
    setIsSuperAdmin(e) {
      if (e === 'superAdminLoginData') this.$store.commit('auth/setIsSuperAdmin', true)
      else this.$store.commit('auth/setIsSuperAdmin', false)
    },
    login() {
      const { userEmail, password, selectedUserType } = this
      this.$store.dispatch('auth/login', { userEmail, password, selectedUserType })
        .then(() => {
          this.redirectToHomePage()
        }).catch(() => {
          this.$swal(
            'Invalid',
            'Invalid Credentials',
            'warning',
          )
        })
    },
    redirectToHomePage() {
      this.$router.push(getHomeRouteForLoggedInUser())
    },
  },
}
</script>

<style lang="scss">

.carousel{
  height:100vh;
  background-color: #24BDC6;
}

.logo-img {
    width: 30%;
}

.logo {
    text-align-last: center;
    margin-bottom: 2rem;
}

.has-icon .form-control {
    padding-left: 3rem;
    padding-right: 3rem;
}
.has-icon .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 1.5rem;
    margin-top: 5px;
    margin-left: 5px;
    height: 2rem;
    line-height: 5rem;
    text-align: center;
    pointer-events: none;
}

.has-icon .form-control-eye {
    display: inline;
    width: 2rem;
    height: 2rem;
    float: right;
    position: relative;
    bottom: 2.3rem;
    right: 1rem;
    cursor: pointer;
}

input#email, input#password {
    border: 1px solid #24BDC6;
}

.email-label, .pass-label{
    position: relative;
    background-color: #f8f8f8;
    top: 13px;
    left: 8%;
    font-size: 12px;
    color: #21BBC4;
}

label.form-check-label {
    margin-left: 29px;
    font-size: 14px;
}

input#remember-check {
    height: 18px;
    width: 18px;
    margin: 0;
}

.button{
  width: 100%;
  background-color: #21BBC4 !important;
  border-color: #21BBC4 !important;
}

.sign-up a {
    color: black;
    font-weight: bold;
    text-decoration: underline;
}

.login-details {
    width: 50%;
}

.sign-up {
    font-size: 16px;
    color: black !important;
    font-weight: 500;
}

.slick-dots li button:before {
  font-family: 'slick';
  font-size: 100px;
  line-height: 0;
  position: absolute;
  top: 0;
  width: 20px;
  height: 20px;
  content: '-';
  opacity: 0.25;
  color: white;
  border-radius: 50px !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: rgb(255, 255, 255);
}
</style>
