<template>
  <VueSlickCarousel v-bind="settings">
    <div
      v-for="(item,index) in items"
      :key="index"
    >
      <img
        :src="item.image"
        alt="logo"
        width="100%"
      >
      <p class="pt-2 text-light">
        {{ item.text }}
      </p>
    </div>
  </VueSlickCarousel>
</template>

<script>
/* eslint-disable global-require */
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

export default {
  name: 'Carousel',
  components: {
    VueSlickCarousel,
  },
  data() {
    return {
      items: [
        { image: require('@/assets/images/slides/business.svg'), text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque, tempore.' },
        { image: require('@/assets/images/slides/orgs.svg'), text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque, tempore.' },
        { image: require('@/assets/images/slides/super-admin.svg'), text: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Cumque, tempore.' },
      ],
      settings: {
        dots: true,
        arrows: false,
        dotsClass: 'slick-dots custom-dot-class',
        infinite: true,
        autoplay: true,
        autoplaySpeed: 5000,
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
